<template>
  <div>
    <el-col :span="24">
      <el-table
        class="table-normal animate__animated animate__fadeIn animate__slow"
        :data="tableData"
        style="width: 100%"
        v-loading="loading"
        element-loading-text="กำลังโหลดข้อมูล..."
        element-loading-spinner="el-icon-loading"
      >
        <template slot="empty">
          <p class="font-30 font-weight-500">ไม่พบข้อมูลที่คุณค้นหา</p>
        </template>
        <!-- <el-table-column label="ชื่อ" prop="name" min-width="150px">
        </el-table-column>
        <el-table-column label="นามสกุล" prop="surname" min-width="150px">
        </el-table-column> -->
        <el-table-column align="center" label="ชื่อบริษัท" min-width="200">
          <template slot-scope="scope">
            <el-tooltip
              class="item"
              effect="dark"
              :content="returnTooltip(scope.row.businessSize)"
              placement="bottom-start"
            >
              <span>{{ scope.row.companyName }}</span>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column align="center" label="เลขนิติบุคคล" width="200">
          <template slot-scope="scope">
            <div class="custom-juristicNumber">
              <span class="show-juristicId">
                {{ scope.row.juristicId }}
                <span
                  class="detail-customDepartment"
                  :class="
                    isImporter(scope.row.importer) &&
                    isExporter(scope.row.exporter)
                      ? 'two'
                      : ''
                  "
                >
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="Importer"
                    placement="bottom"
                  >
                    <div slot="content">
                      <span
                        v-if="
                          isImporter(scope.row.importer) &&
                          isExporter(scope.row.exporter)
                        "
                      >
                        - ผู้นำเข้า (Importer)
                        <br />
                        - ผู้ส่งออก (Exporter)
                      </span>
                      <span v-else-if="isImporter(scope.row.importer)"
                        >ผู้นำเข้า (Importer)</span
                      >
                      <span v-else-if="isExporter(scope.row.exporter)"
                        >ผู้ส่งออก (Exporter)</span
                      >
                    </div>
                    <div>
                      <span v-if="hasCustomDepartment(scope.row) != null">
                        {{ hasCustomDepartment(scope.row) }}
                      </span>
                      <!-- <span
                        v-if="
                          isImporter(scope.row.importer) &&
                          isExporter(scope.row.exporter)
                        "
                        >[IM,EX]</span
                      >
                      <span v-else-if="isImporter(scope.row.importer)"
                        >[IM]</span
                      >
                      <span v-else-if="isExporter(scope.row.exporter)"
                        >[EX]</span
                      > -->
                    </div>
                    <!-- <img :src="require(`@/assets/iconImport.png`)" alt="" /> -->
                  </el-tooltip>
                </span>
              </span>

              <!-- <span
                class="pd-l-6"
                v-if="scope.row.importer == 'Importer'"
              >
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="Importer"
                  placement="bottom"
                >
                  <img :src="require(`@/assets/iconImport.png`)" alt="" />
                </el-tooltip>
              </span> -->
              <!--   <span
                class="pd-l-6 is-flex"
                v-if="scope.row.exporter == 'Exporter'"
              >
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="Exporter"
                  placement="bottom"
                >
                  <img :src="require(`@/assets/iconExport.png`)" alt="" />
                </el-tooltip>
              </span> -->
              <!-- <span class="pd-l-6 is-flex" v-if="scope.row.customDepartment">
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="ข้อมูลจากกรมศุลกากร"
                  placement="bottom"
                >
                  <img src="@/assets/police.svg" alt="" />
                </el-tooltip>
              </span> -->
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="สถานะลูกหนี้" width="100">
          <template slot-scope="scope">
            <span v-if="scope.row.debtorStatus != ''">
              <span
                v-if="
                  scope.row.debtorStatus.toLowerCase() == 'y' ||
                  scope.row.debtorStatus.toLowerCase() == 'yes'
                "
              >
                NPLs
              </span>
              <span
                v-else-if="
                  scope.row.debtorStatus.toLowerCase() == 'n' ||
                  scope.row.debtorStatus.toLowerCase() == 'no'
                "
              >
                ไม่เป็น NPLs
              </span>
            </span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column
          label="เกรดสินเชื่อ"
          align="center"
          prop="gradeName"
          min-width="110"
          v-if="user.role.roleLevel != 60"
        >
        </el-table-column>
        <el-table-column
          align="center"
          label="ผลิตภัณฑ์ที่นำเสนอ"
          min-width="140"
        >
          <template slot-scope="scope">
            <div
              v-if="
                scope.row.productOffers.credit.length > 0 ||
                scope.row.productOffers.insurance.length > 0 ||
                scope.row.productOffers.project.length > 0
              "
              class="productOffers"
            >
              <el-tooltip
                v-for="(credit, indexC) in scope.row.productOffers.credit"
                :key="`credit` + indexC"
                class="item"
                effect="dark"
                :content="credit.creditName"
                placement="top-start"
                v-if="
                  (user.role.roleLevel == 60 &&
                    scope.row.productOffers.credit.length > 0 &&
                    scope.row.productOffers.insurance.length > 0) ||
                  (user.role.roleLevel == 70 &&
                    scope.row.productOffers.credit.length > 0) ||
                  (user.role.roleLevel != 60 &&
                    user.role.roleLevel != 70 &&
                    scope.row.productOffers.credit.length > 0)
                "
              >
                <span style="color: #0d47a1">{{
                  credit.productProgramName
                }}</span>
              </el-tooltip>
              <el-tooltip
                v-for="(insurance, indexI) in scope.row.productOffers.insurance"
                :key="`insurance` + indexI"
                class="item"
                effect="dark"
                :content="insurance.insuranceName"
                placement="top-start"
                v-if="
                  (user.role.roleLevel == 70 &&
                    scope.row.productOffers.credit.length > 0 &&
                    scope.row.productOffers.insurance.length > 0) ||
                  (user.role.roleLevel == 60 &&
                    scope.row.productOffers.insurance.length > 0) ||
                  (user.role.roleLevel != 60 &&
                    user.role.roleLevel != 70 &&
                    scope.row.productOffers.insurance.length > 0)
                "
              >
                <span style="color: #ff0033">{{
                  insurance.insuranceProductId
                }}</span>
              </el-tooltip>
              <el-tooltip
                class="item"
                effect="dark"
                placement="top-start"
                v-if="scope.row.productOffers.project.length > 0"
              >
                <div
                  slot="content"
                  v-html="returnProject(scope.row.productOffers.project)"
                ></div>
                <span style="color: #1b2631">โครงการอบรม</span>
              </el-tooltip>
            </div>
            <div v-else>-</div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="ผลิตภัณฑ์ที่สนใจ"
          min-width="140"
        >
          <template slot-scope="scope">
            <div
              v-if="
                scope.row.interest.credit.length > 0 ||
                scope.row.interest.insurance.length > 0 ||
                scope.row.interest.project.length > 0
              "
            >
              <!-- permissionCredit" -->
              <span
                v-if="
                  (user.role.roleLevel == 60 &&
                    scope.row.interest.credit.length > 0 &&
                    scope.row.interest.insurance.length > 0) ||
                  (user.role.roleLevel == 70 &&
                    scope.row.interest.credit.length > 0) ||
                  (user.role.roleLevel != 60 &&
                    user.role.roleLevel != 70 &&
                    scope.row.interest.credit.length > 0)
                "
              >
                <span
                  v-for="(wCredit, indexC) in scope.row.interest.credit"
                  :key="`credit` + indexC"
                >
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="wCredit.creditName"
                    placement="top-start"
                  >
                    <span style="color: #0d47a1">{{
                      wCredit.productProgramName
                    }}</span>
                  </el-tooltip>
                  <span>{{
                    indexC != scope.row.interest.credit.length - 1 ? ", " : ""
                  }}</span>
                </span>
                <span
                  v-if="
                    scope.row.interest.credit.length == 0 &&
                    scope.row.interest.project.length == 0 &&
                    permissionCredit &&
                    !permissionInsurance
                  "
                >
                  -
                </span>
              </span>
              <span
                v-if="
                  user.role.roleLevel == 60 &&
                  scope.row.interest.credit.length >= 0 &&
                  scope.row.interest.project.length == 0 &&
                  scope.row.interest.insurance.length == 0
                "
                >-</span
              >
              <span
                v-if="
                  user.role.roleLevel == 70 &&
                  scope.row.interest.credit.length == 0 &&
                  scope.row.interest.project.length == 0 &&
                  scope.row.interest.insurance.length >= 0
                "
                >-</span
              >
              <span
                v-if="
                  (scope.row.interest.credit.length > 0 &&
                    scope.row.interest.insurance.length > 0 &&
                    permissionCredit &&
                    permissionInsurance) ||
                  (user.role.roleLevel == 70 &&
                    scope.row.interest.credit.length > 0 &&
                    scope.row.interest.project.length > 0 &&
                    permissionCredit &&
                    permissionInsurance) ||
                  (user.role.roleLevel != 60 &&
                    user.role.roleLevel != 70 &&
                    scope.row.interest.credit.length > 0 &&
                    scope.row.interest.insurance.length > 0) ||
                  (scope.row.interest.credit.length > 0 &&
                    scope.row.interest.project.length > 0)
                "
              >
                ,
              </span>
              <!-- permissionInsurance" -->
              <span
                v-if="
                  (user.role.roleLevel == 70 &&
                    scope.row.interest.credit.length > 0 &&
                    scope.row.interest.insurance.length > 0) ||
                  (user.role.roleLevel == 60 &&
                    scope.row.interest.insurance.length > 0) ||
                  (user.role.roleLevel != 60 &&
                    user.role.roleLevel != 70 &&
                    scope.row.interest.insurance.length > 0)
                "
              >
                <span
                  v-for="(WInsurance, indexI) in scope.row.interest.insurance"
                  :key="`insurance` + indexI"
                >
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="WInsurance.insuranceName"
                    placement="top-start"
                  >
                    <span style="color: #ff0033">{{
                      WInsurance.insuranceProductId
                    }}</span>
                  </el-tooltip>
                  <span>{{
                    indexI != scope.row.interest.insurance.length - 1
                      ? ", "
                      : ""
                  }}</span>
                </span>
                <span
                  v-if="
                    scope.row.interest.insurance.length == 0 &&
                    scope.row.interest.project.length == 0 &&
                    !permissionCredit &&
                    permissionInsurance
                  "
                >
                  -
                </span>
              </span>
              <span
                v-if="
                  (user.role.roleLevel == 70 &&
                    scope.row.interest.credit.length > 0 &&
                    scope.row.interest.insurance.length > 0 &&
                    scope.row.interest.project.length > 0) ||
                  (user.role.roleLevel == 60 &&
                    scope.row.interest.insurance.length > 0 &&
                    permissionInsurance &&
                    scope.row.interest.project.length > 0) ||
                  (user.role.roleLevel != 60 &&
                    user.role.roleLevel != 70 &&
                    scope.row.interest.insurance.length > 0 &&
                    scope.row.interest.project.length > 0)
                "
              >
                ,
              </span>
              <span v-if="scope.row.interest.project.length > 0">
                <el-tooltip class="item" effect="dark" placement="top-start">
                  <div
                    slot="content"
                    v-html="returnProject(scope.row.interest.project)"
                  ></div>
                  <span style="color: #1b2631"> โครงการอบรม </span>
                </el-tooltip>
              </span>
            </div>
            <div v-else>-</div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="สถานะ Lead สินเชื่อ" width="110">
          <template slot-scope="scope">
            <span v-if="scope.row.lead == 0" style="color: #9c9c9c">
              Close</span
            >
            <span v-else-if="scope.row.lead == 1" style="color: #3598ff"
              >New
            </span>
            <span v-else-if="scope.row.lead == 2" style="color: #fac858"
              >Pending
            </span>
            <span v-else-if="scope.row.lead == 3" style="color: #38d85b"
              >Active
            </span>
            <span v-else-if="scope.row.lead == 4" style="color: #034ea2"
              >Submitted
            </span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column
          label="สถานะของการเข้าพบลูกค้า"
          align="center"
          width="140"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.meetStatus">
              {{ scope.row.meetStatus }}
            </span>
            <span v-else>-</span>
          </template>
        </el-table-column>

        <el-table-column label="สถานะสินเชื่อ" align="center" width="100">
          <template slot-scope="scope">
            <span
              class="color-main text-decoration-underline cursor-pointer"
              @click="
                moreStatus(
                  scope.row.juristicId,
                  scope.row.companyId,
                  scope.row.branch
                )
              "
              v-if="
                (user.role.roleLevel == 60 &&
                  scope.row.interest.credit.length > 0 &&
                  scope.row.interest.insurance.length > 0) ||
                (user.role.roleLevel == 70 &&
                  scope.row.interest.credit.length > 0) ||
                (user.role.roleLevel != 70 &&
                  user.role.roleLevel != 60 &&
                  scope.row.interest.credit.length > 0)
              "
              >ดูสถานะ
            </span>
            <!-- v-if="scope.row.statusCredit && permissionCredit" -->
            <span v-else>-</span>
          </template>
        </el-table-column>

        <el-table-column label="สถานะประกัน" width="100px" align="center">
          <template slot-scope="scope">
            <span
              class="color-ed text-decoration-underline cursor-pointer"
              @click="
                moreInsurance(
                  scope.row.juristicId,
                  scope.row.companyId,
                  scope.row.branch
                )
              "
              v-if="
                (user.role.roleLevel == 70 &&
                  scope.row.interest.credit.length > 0 &&
                  scope.row.interest.insurance.length > 0) ||
                (user.role.roleLevel == 60 &&
                  scope.row.interest.insurance.length > 0) ||
                (user.role.roleLevel != 70 &&
                  user.role.roleLevel != 60 &&
                  scope.row.interest.insurance.length > 0)
              "
              >ดูสถานะ</span
            >
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column label="ที่มา" align="center" width="140x">
          <template slot-scope="scope">
            <!-- <span>{{ scope.row.branch }}</span> -->
            <div
              v-if="
                scope.row.projectTeraks != null && scope.row.projectTeraks != ''
              "
            >
              <el-tooltip
                class="item"
                effect="dark"
                :content="projectFromTERAK(scope.row.projectTeraks)"
                placement="bottom-start"
              >
                <span>{{ scope.row.branch }}</span>
              </el-tooltip>
            </div>
            <div v-else>
              <span>{{ scope.row.branch }}</span>
            </div>
            <!-- <span v-if="scope.$index % 2 == 0">TERAK</span>
            <span v-else>Channel Integration</span> -->
          </template>
        </el-table-column>
        <el-table-column align="center" width="60px">
          <template slot-scope="scope">
            <el-popover
              :popper-class="'user-detail'"
              placement="bottom"
              trigger="click"
              @show="checkDownloadFinancial(scope.row.juristicId)"
              @after-leave="clearload"
            >
              <i
                slot="reference"
                class="fas fa-ellipsis-v"
                style="color: #9c9c9c; cursor: pointer"
              ></i>
              <div class="box-poppopover pd-b-6">
                <!-- <router-link :to="`/user/personal/${scope.row.companyId}`"
                  >ดูข้อมูลส่วนตัว</router-link
                > -->
                <a
                  class="cursor-pointer"
                  @click="
                    redirect2Profile(scope.row.companyId, scope.row.criterionId)
                  "
                  v-if="checkPersonal"
                  >ดูข้อมูลส่วนตัว</a
                >
                <a
                  class="cursor-pointer"
                  @click="
                    redirectToCompany(
                      scope.row.companyId,
                      scope.row.criterionId
                    )
                  "
                  v-if="checkCompany"
                  >ดูข้อมูลบริษัท</a
                >
                <!-- <router-link :to="`/user/company/${scope.row.companyId}`"
                  >ดูข้อมูลบริษัท</router-link
                > -->
              </div>
              <div
                v-if="
                  (user.role.roleLevel == 70 &&
                    scope.row.interest.credit.length > 0) ||
                  (user.role.roleLevel == 60 &&
                    scope.row.interest.insurance.length > 0) ||
                  scope.row.interest.project.length > 0 ||
                  (user.role.roleLevel != 70 &&
                    user.role.roleLevel != 60 &&
                    (scope.row.interest.credit.length > 0 ||
                      scope.row.interest.insurance.length > 0 ||
                      scope.row.interest.project.length > 0))
                "
              >
                <!-- scope.row.interest.credit.length > 0 ||
                  scope.row.interest.insurance.length > 0 ||
                  scope.row.interest.project.length > 0 -->
                <div class="box-poppopover box pd-b-6" v-if="checkCompanyMore">
                  <!-- <router-link :to="`/user/credit/${scope.row.juristicId}`"
                  >สินเชื่อที่สนใจ</router-link
                > -->
                  <!-- permissionCredit && scope.row.interest.credit.length > 0 -->
                  <a
                    v-if="
                      (user.role.roleLevel == 60 &&
                        scope.row.interest.credit.length > 0 &&
                        scope.row.interest.insurance.length > 0) ||
                      (user.role.roleLevel == 70 &&
                        scope.row.interest.credit.length > 0) ||
                      (user.role.roleLevel != 70 &&
                        user.role.roleLevel != 60 &&
                        scope.row.interest.credit.length > 0)
                    "
                    class="cursor-pointer"
                    @click="
                      redirect2InterestCredit(
                        scope.row.juristicId,
                        scope.row.criterionId,
                        scope.row.branch
                      )
                    "
                    >สินเชื่อที่สนใจ
                  </a>
                  <!-- <router-link :to="`/user/insurance/${scope.row.companyId}`"
                  >ประกันที่สนใจ</router-link
                > -->
                  <!-- permissionInsurance &&
                      scope.row.interest.insurance.length > 0 -->
                  <a
                    v-if="
                      (user.role.roleLevel == 70 &&
                        scope.row.interest.credit.length > 0 &&
                        scope.row.interest.insurance.length > 0) ||
                      (user.role.roleLevel == 60 &&
                        scope.row.interest.insurance.length > 0) ||
                      (user.role.roleLevel != 70 &&
                        user.role.roleLevel != 60 &&
                        scope.row.interest.insurance.length > 0)
                    "
                    class="cursor-pointer"
                    @click="
                      redirect2InterestIns(
                        scope.row.juristicId,
                        scope.row.criterionId,
                        scope.row.branch
                      )
                    "
                    >ประกันที่สนใจ
                  </a>
                  <!-- <router-link :to="`/user/project/${scope.row.companyId}`"
                  >โครงการที่สนใจ</router-link
                > -->
                  <a
                    v-if="scope.row.interest.project.length > 0"
                    class="cursor-pointer"
                    @click="
                      redirect2InterestPj(
                        scope.row.juristicId,
                        scope.row.criterionId
                      )
                    "
                    >โครงการที่สนใจ</a
                  >
                </div>
              </div>

              <div class="box-poppopover box">
                <el-button
                  :loading="loadShowFinancial"
                  class="btn-show-financial"
                >
                  <a
                    @click="
                      downloadFinancial(scope.row.juristicId, scope.row._id)
                    "
                    class="cursor-pointer"
                    v-if="checkDownload"
                    >Download Financial Projection
                    <i
                      class="fas fa-check-circle color-main"
                      v-if="showContactFinancial"
                    ></i>
                  </a>
                </el-button>
              </div>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
    </el-col>
  </div>
</template>
<script>
import { HTTP } from "@/service/axios";
import { mapState } from "vuex";
export default {
  props: [
    "user",
    "tableData",
    "loading",
    "checkPersonal",
    "checkCompany",
    "checkDownload",
    "checkCompanyMore",
    "criteriaUserId",
    "permissionCredit",
    "permissionInsurance",
  ],
  computed: {
    ...mapState({
      _token: (state) => state._token,
    }),
  },
  data() {
    return {
      showFinancial: false,
      showContactFinancial: false,
      loadShowFinancial: true,
    };
  },
  methods: {
    projectFromTERAK(data) {
      return `โครงการที่เข้าร่วม : ${data}`;
    },
    hasCustomDepartment(data) {
      if (typeof data == "undefined") {
        return null;
      }

      var _wordArr = [];
      // importer
      if (
        data.importer != null &&
        data.importer != "" &&
        data.importer != undefined &&
        data.importer != "ไม่มีข้อมูล"
      ) {
        switch (data.importer) {
          case "Continue 8 Y":
            _wordArr.push("IM-8Y");
            break;
          case "Continue 5 Y":
            _wordArr.push("IM-5Y");
            break;
          case "Non-Continue":
            _wordArr.push("IM");
            break;
          default:
            _wordArr.push("IM");
            break;
        }
      }

      // Exporter
      if (
        data.exporter != null &&
        data.exporter != "" &&
        data.exporter != undefined &&
        data.exporter != "ไม่มีข้อมูล"
      ) {
        switch (data.exporter) {
          case "Continue 8 Y":
            _wordArr.push("EX-8Y");
            break;
          case "Continue 5 Y":
            _wordArr.push("EX-5Y");
            break;
          case "Non-Continue":
            _wordArr.push("EX");
          default:
            _wordArr.push("EX");
            break;
        }
      }

      // word check
      if (_wordArr.length == 0) {
        return null;
      }

      // Array Concat Word
      const result = _wordArr.join(", ");
      return `[${result}]`;
    },
    isImporter(importer) {
      return !!importer && importer != "ไม่มีข้อมูล";
    },
    isExporter(exporter) {
      return !!exporter && exporter != "ไม่มีข้อมูล";
    },
    clearload() {
      this.showFinancial = false;
      this.loadShowFinancial = true;
    },
    async checkDownloadFinancial(id) {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      let res = await HTTP.get(`user/financial/contract/${id}`) // /user
        .then((res) => {
          if (res.data.success) {
            if (res.data.obj != null) {
              if (res.data.obj.downloadStatus == true) {
                this.showFinancial = true;
              } else {
                this.showFinancial = false;
              }
              if (res.data.obj.contactFinancialProjection == true) {
                this.showContactFinancial = true;
              } else {
                this.showContactFinancial = false;
              }
            } else {
              this.showFinancial = false;
              this.showContactFinancial = false;
            }
            this.loadShowFinancial = false;
          }
        });
      return res;
    },
    returnProject(arr) {
      let result = "";
      arr.forEach((row, index) => {
        result += "- " + row.projectName;
        if (index != arr.length - 1) {
          result += "<br/>";
        }
      });
      return result;
    },
    returnTooltip(name) {
      let result = "";
      if (name.toLowerCase() == "s") {
        result = "ธุรกิจขนาดเล็ก (S)";
      } else if (name.toLowerCase() == "m") {
        result = "ธุรกิจขนาดกลาง (M)";
      } else if (name.toLowerCase() == "l") {
        result = "ธุรกิจขนาดใหญ่ (L)";
      }
      return result;
    },
    moreStatus(juristicId, companyId, branch) {
      this.$emit("moreCredit", juristicId, companyId, branch);
    },
    moreInsurance(juristicId, companyId, branch) {
      this.$emit("moreInsurance", juristicId, companyId, branch);
    },
    downloadFinancial(juristicId, _id) {
      this.$emit(
        "downloadFinancial",
        juristicId,
        _id,
        this.showFinancial,
        this.showContactFinancial
      );
    },
    redirectToCompany(id, criterionId) {
      window.open(`/user/company/${id}/${criterionId}`);
    },
    redirect2Profile(id, criterionId) {
      window.open(`/user/personal/${id}/${criterionId}`);
    },
    redirect2InterestCredit(id, criterionId, branch) {
      window.open(`/user/credit/${id}/${criterionId}/${branch}`);
    },
    redirect2InterestIns(id, criterionId, branch) {
      window.open(`/user/insurance/${id}/${criterionId}/${branch}`);
    },
    redirect2InterestPj(id, criterionId) {
      window.open(`/user/project/${id}/${criterionId}`);
    },
  },
};
</script>
