var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-col',{attrs:{"span":24}},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"table-normal animate__animated animate__fadeIn animate__slow",staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"element-loading-text":"กำลังโหลดข้อมูล...","element-loading-spinner":"el-icon-loading"}},[_c('template',{slot:"empty"},[_c('p',{staticClass:"font-30 font-weight-500"},[_vm._v("ไม่พบข้อมูลที่คุณค้นหา")])]),_c('el-table-column',{attrs:{"align":"center","label":"ชื่อบริษัท","min-width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":_vm.returnTooltip(scope.row.businessSize),"placement":"bottom-start"}},[_c('span',[_vm._v(_vm._s(scope.row.companyName))])])]}}])}),_c('el-table-column',{attrs:{"align":"center","label":"เลขนิติบุคคล","width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"custom-juristicNumber"},[_c('span',{staticClass:"show-juristicId"},[_vm._v(" "+_vm._s(scope.row.juristicId)+" "),_c('span',{staticClass:"detail-customDepartment",class:_vm.isImporter(scope.row.importer) &&
                  _vm.isExporter(scope.row.exporter)
                    ? 'two'
                    : ''},[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"Importer","placement":"bottom"}},[_c('div',{attrs:{"slot":"content"},slot:"content"},[(
                        _vm.isImporter(scope.row.importer) &&
                        _vm.isExporter(scope.row.exporter)
                      )?_c('span',[_vm._v(" - ผู้นำเข้า (Importer) "),_c('br'),_vm._v(" - ผู้ส่งออก (Exporter) ")]):(_vm.isImporter(scope.row.importer))?_c('span',[_vm._v("ผู้นำเข้า (Importer)")]):(_vm.isExporter(scope.row.exporter))?_c('span',[_vm._v("ผู้ส่งออก (Exporter)")]):_vm._e()]),_c('div',[(_vm.hasCustomDepartment(scope.row) != null)?_c('span',[_vm._v(" "+_vm._s(_vm.hasCustomDepartment(scope.row))+" ")]):_vm._e()])])],1)])])]}}])}),_c('el-table-column',{attrs:{"align":"center","label":"สถานะลูกหนี้","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.debtorStatus != '')?_c('span',[(
                scope.row.debtorStatus.toLowerCase() == 'y' ||
                scope.row.debtorStatus.toLowerCase() == 'yes'
              )?_c('span',[_vm._v(" NPLs ")]):(
                scope.row.debtorStatus.toLowerCase() == 'n' ||
                scope.row.debtorStatus.toLowerCase() == 'no'
              )?_c('span',[_vm._v(" ไม่เป็น NPLs ")]):_vm._e()]):_c('span',[_vm._v("-")])]}}])}),(_vm.user.role.roleLevel != 60)?_c('el-table-column',{attrs:{"label":"เกรดสินเชื่อ","align":"center","prop":"gradeName","min-width":"110"}}):_vm._e(),_c('el-table-column',{attrs:{"align":"center","label":"ผลิตภัณฑ์ที่นำเสนอ","min-width":"140"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
              scope.row.productOffers.credit.length > 0 ||
              scope.row.productOffers.insurance.length > 0 ||
              scope.row.productOffers.project.length > 0
            )?_c('div',{staticClass:"productOffers"},[_vm._l((scope.row.productOffers.credit),function(credit,indexC){return (
                (_vm.user.role.roleLevel == 60 &&
                  scope.row.productOffers.credit.length > 0 &&
                  scope.row.productOffers.insurance.length > 0) ||
                (_vm.user.role.roleLevel == 70 &&
                  scope.row.productOffers.credit.length > 0) ||
                (_vm.user.role.roleLevel != 60 &&
                  _vm.user.role.roleLevel != 70 &&
                  scope.row.productOffers.credit.length > 0)
              )?_c('el-tooltip',{key:`credit` + indexC,staticClass:"item",attrs:{"effect":"dark","content":credit.creditName,"placement":"top-start"}},[_c('span',{staticStyle:{"color":"#0d47a1"}},[_vm._v(_vm._s(credit.productProgramName))])]):_vm._e()}),_vm._l((scope.row.productOffers.insurance),function(insurance,indexI){return (
                (_vm.user.role.roleLevel == 70 &&
                  scope.row.productOffers.credit.length > 0 &&
                  scope.row.productOffers.insurance.length > 0) ||
                (_vm.user.role.roleLevel == 60 &&
                  scope.row.productOffers.insurance.length > 0) ||
                (_vm.user.role.roleLevel != 60 &&
                  _vm.user.role.roleLevel != 70 &&
                  scope.row.productOffers.insurance.length > 0)
              )?_c('el-tooltip',{key:`insurance` + indexI,staticClass:"item",attrs:{"effect":"dark","content":insurance.insuranceName,"placement":"top-start"}},[_c('span',{staticStyle:{"color":"#ff0033"}},[_vm._v(_vm._s(insurance.insuranceProductId))])]):_vm._e()}),(scope.row.productOffers.project.length > 0)?_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","placement":"top-start"}},[_c('div',{attrs:{"slot":"content"},domProps:{"innerHTML":_vm._s(_vm.returnProject(scope.row.productOffers.project))},slot:"content"}),_c('span',{staticStyle:{"color":"#1b2631"}},[_vm._v("โครงการอบรม")])]):_vm._e()],2):_c('div',[_vm._v("-")])]}}])}),_c('el-table-column',{attrs:{"align":"center","label":"ผลิตภัณฑ์ที่สนใจ","min-width":"140"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
              scope.row.interest.credit.length > 0 ||
              scope.row.interest.insurance.length > 0 ||
              scope.row.interest.project.length > 0
            )?_c('div',[(
                (_vm.user.role.roleLevel == 60 &&
                  scope.row.interest.credit.length > 0 &&
                  scope.row.interest.insurance.length > 0) ||
                (_vm.user.role.roleLevel == 70 &&
                  scope.row.interest.credit.length > 0) ||
                (_vm.user.role.roleLevel != 60 &&
                  _vm.user.role.roleLevel != 70 &&
                  scope.row.interest.credit.length > 0)
              )?_c('span',[_vm._l((scope.row.interest.credit),function(wCredit,indexC){return _c('span',{key:`credit` + indexC},[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":wCredit.creditName,"placement":"top-start"}},[_c('span',{staticStyle:{"color":"#0d47a1"}},[_vm._v(_vm._s(wCredit.productProgramName))])]),_c('span',[_vm._v(_vm._s(indexC != scope.row.interest.credit.length - 1 ? ", " : ""))])],1)}),(
                  scope.row.interest.credit.length == 0 &&
                  scope.row.interest.project.length == 0 &&
                  _vm.permissionCredit &&
                  !_vm.permissionInsurance
                )?_c('span',[_vm._v(" - ")]):_vm._e()],2):_vm._e(),(
                _vm.user.role.roleLevel == 60 &&
                scope.row.interest.credit.length >= 0 &&
                scope.row.interest.project.length == 0 &&
                scope.row.interest.insurance.length == 0
              )?_c('span',[_vm._v("-")]):_vm._e(),(
                _vm.user.role.roleLevel == 70 &&
                scope.row.interest.credit.length == 0 &&
                scope.row.interest.project.length == 0 &&
                scope.row.interest.insurance.length >= 0
              )?_c('span',[_vm._v("-")]):_vm._e(),(
                (scope.row.interest.credit.length > 0 &&
                  scope.row.interest.insurance.length > 0 &&
                  _vm.permissionCredit &&
                  _vm.permissionInsurance) ||
                (_vm.user.role.roleLevel == 70 &&
                  scope.row.interest.credit.length > 0 &&
                  scope.row.interest.project.length > 0 &&
                  _vm.permissionCredit &&
                  _vm.permissionInsurance) ||
                (_vm.user.role.roleLevel != 60 &&
                  _vm.user.role.roleLevel != 70 &&
                  scope.row.interest.credit.length > 0 &&
                  scope.row.interest.insurance.length > 0) ||
                (scope.row.interest.credit.length > 0 &&
                  scope.row.interest.project.length > 0)
              )?_c('span',[_vm._v(" , ")]):_vm._e(),(
                (_vm.user.role.roleLevel == 70 &&
                  scope.row.interest.credit.length > 0 &&
                  scope.row.interest.insurance.length > 0) ||
                (_vm.user.role.roleLevel == 60 &&
                  scope.row.interest.insurance.length > 0) ||
                (_vm.user.role.roleLevel != 60 &&
                  _vm.user.role.roleLevel != 70 &&
                  scope.row.interest.insurance.length > 0)
              )?_c('span',[_vm._l((scope.row.interest.insurance),function(WInsurance,indexI){return _c('span',{key:`insurance` + indexI},[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":WInsurance.insuranceName,"placement":"top-start"}},[_c('span',{staticStyle:{"color":"#ff0033"}},[_vm._v(_vm._s(WInsurance.insuranceProductId))])]),_c('span',[_vm._v(_vm._s(indexI != scope.row.interest.insurance.length - 1 ? ", " : ""))])],1)}),(
                  scope.row.interest.insurance.length == 0 &&
                  scope.row.interest.project.length == 0 &&
                  !_vm.permissionCredit &&
                  _vm.permissionInsurance
                )?_c('span',[_vm._v(" - ")]):_vm._e()],2):_vm._e(),(
                (_vm.user.role.roleLevel == 70 &&
                  scope.row.interest.credit.length > 0 &&
                  scope.row.interest.insurance.length > 0 &&
                  scope.row.interest.project.length > 0) ||
                (_vm.user.role.roleLevel == 60 &&
                  scope.row.interest.insurance.length > 0 &&
                  _vm.permissionInsurance &&
                  scope.row.interest.project.length > 0) ||
                (_vm.user.role.roleLevel != 60 &&
                  _vm.user.role.roleLevel != 70 &&
                  scope.row.interest.insurance.length > 0 &&
                  scope.row.interest.project.length > 0)
              )?_c('span',[_vm._v(" , ")]):_vm._e(),(scope.row.interest.project.length > 0)?_c('span',[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","placement":"top-start"}},[_c('div',{attrs:{"slot":"content"},domProps:{"innerHTML":_vm._s(_vm.returnProject(scope.row.interest.project))},slot:"content"}),_c('span',{staticStyle:{"color":"#1b2631"}},[_vm._v(" โครงการอบรม ")])])],1):_vm._e()]):_c('div',[_vm._v("-")])]}}])}),_c('el-table-column',{attrs:{"align":"center","label":"สถานะ Lead สินเชื่อ","width":"110"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.lead == 0)?_c('span',{staticStyle:{"color":"#9c9c9c"}},[_vm._v(" Close")]):(scope.row.lead == 1)?_c('span',{staticStyle:{"color":"#3598ff"}},[_vm._v("New ")]):(scope.row.lead == 2)?_c('span',{staticStyle:{"color":"#fac858"}},[_vm._v("Pending ")]):(scope.row.lead == 3)?_c('span',{staticStyle:{"color":"#38d85b"}},[_vm._v("Active ")]):(scope.row.lead == 4)?_c('span',{staticStyle:{"color":"#034ea2"}},[_vm._v("Submitted ")]):_c('span',[_vm._v("-")])]}}])}),_c('el-table-column',{attrs:{"label":"สถานะของการเข้าพบลูกค้า","align":"center","width":"140"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.meetStatus)?_c('span',[_vm._v(" "+_vm._s(scope.row.meetStatus)+" ")]):_c('span',[_vm._v("-")])]}}])}),_c('el-table-column',{attrs:{"label":"สถานะสินเชื่อ","align":"center","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
              (_vm.user.role.roleLevel == 60 &&
                scope.row.interest.credit.length > 0 &&
                scope.row.interest.insurance.length > 0) ||
              (_vm.user.role.roleLevel == 70 &&
                scope.row.interest.credit.length > 0) ||
              (_vm.user.role.roleLevel != 70 &&
                _vm.user.role.roleLevel != 60 &&
                scope.row.interest.credit.length > 0)
            )?_c('span',{staticClass:"color-main text-decoration-underline cursor-pointer",on:{"click":function($event){return _vm.moreStatus(
                scope.row.juristicId,
                scope.row.companyId,
                scope.row.branch
              )}}},[_vm._v("ดูสถานะ ")]):_c('span',[_vm._v("-")])]}}])}),_c('el-table-column',{attrs:{"label":"สถานะประกัน","width":"100px","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
              (_vm.user.role.roleLevel == 70 &&
                scope.row.interest.credit.length > 0 &&
                scope.row.interest.insurance.length > 0) ||
              (_vm.user.role.roleLevel == 60 &&
                scope.row.interest.insurance.length > 0) ||
              (_vm.user.role.roleLevel != 70 &&
                _vm.user.role.roleLevel != 60 &&
                scope.row.interest.insurance.length > 0)
            )?_c('span',{staticClass:"color-ed text-decoration-underline cursor-pointer",on:{"click":function($event){return _vm.moreInsurance(
                scope.row.juristicId,
                scope.row.companyId,
                scope.row.branch
              )}}},[_vm._v("ดูสถานะ")]):_c('span',[_vm._v("-")])]}}])}),_c('el-table-column',{attrs:{"label":"ที่มา","align":"center","width":"140x"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
              scope.row.projectTeraks != null && scope.row.projectTeraks != ''
            )?_c('div',[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":_vm.projectFromTERAK(scope.row.projectTeraks),"placement":"bottom-start"}},[_c('span',[_vm._v(_vm._s(scope.row.branch))])])],1):_c('div',[_c('span',[_vm._v(_vm._s(scope.row.branch))])])]}}])}),_c('el-table-column',{attrs:{"align":"center","width":"60px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-popover',{attrs:{"popper-class":'user-detail',"placement":"bottom","trigger":"click"},on:{"show":function($event){return _vm.checkDownloadFinancial(scope.row.juristicId)},"after-leave":_vm.clearload}},[_c('i',{staticClass:"fas fa-ellipsis-v",staticStyle:{"color":"#9c9c9c","cursor":"pointer"},attrs:{"slot":"reference"},slot:"reference"}),_c('div',{staticClass:"box-poppopover pd-b-6"},[(_vm.checkPersonal)?_c('a',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.redirect2Profile(scope.row.companyId, scope.row.criterionId)}}},[_vm._v("ดูข้อมูลส่วนตัว")]):_vm._e(),(_vm.checkCompany)?_c('a',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.redirectToCompany(
                    scope.row.companyId,
                    scope.row.criterionId
                  )}}},[_vm._v("ดูข้อมูลบริษัท")]):_vm._e()]),(
                (_vm.user.role.roleLevel == 70 &&
                  scope.row.interest.credit.length > 0) ||
                (_vm.user.role.roleLevel == 60 &&
                  scope.row.interest.insurance.length > 0) ||
                scope.row.interest.project.length > 0 ||
                (_vm.user.role.roleLevel != 70 &&
                  _vm.user.role.roleLevel != 60 &&
                  (scope.row.interest.credit.length > 0 ||
                    scope.row.interest.insurance.length > 0 ||
                    scope.row.interest.project.length > 0))
              )?_c('div',[(_vm.checkCompanyMore)?_c('div',{staticClass:"box-poppopover box pd-b-6"},[(
                    (_vm.user.role.roleLevel == 60 &&
                      scope.row.interest.credit.length > 0 &&
                      scope.row.interest.insurance.length > 0) ||
                    (_vm.user.role.roleLevel == 70 &&
                      scope.row.interest.credit.length > 0) ||
                    (_vm.user.role.roleLevel != 70 &&
                      _vm.user.role.roleLevel != 60 &&
                      scope.row.interest.credit.length > 0)
                  )?_c('a',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.redirect2InterestCredit(
                      scope.row.juristicId,
                      scope.row.criterionId,
                      scope.row.branch
                    )}}},[_vm._v("สินเชื่อที่สนใจ ")]):_vm._e(),(
                    (_vm.user.role.roleLevel == 70 &&
                      scope.row.interest.credit.length > 0 &&
                      scope.row.interest.insurance.length > 0) ||
                    (_vm.user.role.roleLevel == 60 &&
                      scope.row.interest.insurance.length > 0) ||
                    (_vm.user.role.roleLevel != 70 &&
                      _vm.user.role.roleLevel != 60 &&
                      scope.row.interest.insurance.length > 0)
                  )?_c('a',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.redirect2InterestIns(
                      scope.row.juristicId,
                      scope.row.criterionId,
                      scope.row.branch
                    )}}},[_vm._v("ประกันที่สนใจ ")]):_vm._e(),(scope.row.interest.project.length > 0)?_c('a',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.redirect2InterestPj(
                      scope.row.juristicId,
                      scope.row.criterionId
                    )}}},[_vm._v("โครงการที่สนใจ")]):_vm._e()]):_vm._e()]):_vm._e(),_c('div',{staticClass:"box-poppopover box"},[_c('el-button',{staticClass:"btn-show-financial",attrs:{"loading":_vm.loadShowFinancial}},[(_vm.checkDownload)?_c('a',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.downloadFinancial(scope.row.juristicId, scope.row._id)}}},[_vm._v("Download Financial Projection "),(_vm.showContactFinancial)?_c('i',{staticClass:"fas fa-check-circle color-main"}):_vm._e()]):_vm._e()])],1)])]}}])})],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }